import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const About = ({ data }) => {
	return (
		<Layout bodyClass="page-teams">
			<SEO title={data.prismicAbout.data.title.text} />
			<Helmet>
				<meta name="description" content={data.prismicAbout.data.intro.text} />
				<link
					rel="canonical"
					href={`${data.site.siteMetadata.siteUrl}en/about/`}
				/>
				<link
					rel="alternate"
					href={`${data.site.siteMetadata.siteUrl}en/about/`}
					hreflang="en"
				/>
				<link
					rel="alternate"
					href={`${data.site.siteMetadata.siteUrl}sv/about/`}
					hreflang="sv-se"
				/>
			</Helmet>
			<div className="container pt-4 pt-md-10 mb-10">
				<div className="row justify-content-start">
					<div className="col-12 col-md-8">
						<div className="service service-single">
							<h1 className="title">{data.prismicAbout.data.title.text}</h1>
							<div
								className="content"
								dangerouslySetInnerHTML={{
									__html: data.prismicAbout.data.content.html
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query {
		site {
			siteMetadata {
				siteUrl
			}
		}
		prismicAbout(lang: { eq: "en-gb" }) {
			data {
				title {
					text
				}
				intro {
					text
				}
				content {
					html
				}
				name {
					text
				}
				image {
					alt
					copyright
					url
				}
				job_title {
					text
				}
				linkedin_url {
					link_type
					url
					target
				}
				email {
					text
				}
				description {
					html
				}
			}
		}
	}
`;

export default About;
